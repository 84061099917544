import styled from "styled-components";
import { H2 } from "../../atoms/H2/H2";
import { Dimensions } from "../../layout/GlobalStyles/Dimensions";
import { Viewport } from "../../layout/GlobalStyles/Viewport";

export const Title = styled(H2)`
    text-align: center;
    padding-top: ${Dimensions.GridUnit * 4}px;
    padding-bottom: ${Dimensions.GridUnit * 4}px;
    @media screen and (max-width: ${Viewport.Medium}px) {
        text-align: center;
        padding-left: ${Dimensions.GridUnit * 2}px;
    }
`;

export const Container = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`;
