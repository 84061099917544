import styled from "styled-components";
import { Color } from "../../../layout/GlobalStyles/Color";
import { Dimensions } from "../../../layout/GlobalStyles/Dimensions";

export const Root = styled.div`
    display: block;
    width: 100%;
`;
export const ContentRoot = styled.div`
    display: block;
    max-width: ${Dimensions.MaxContentWidth}px;
    margin: 0px auto;
    min-height: 100vh;
`;
export const Content = styled.div`
    width: 100%;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    justify-content: center;
    padding-bottom: ${Dimensions.GridUnit * 4}px;
`;

export const JobListRoot = styled.ul`
    display: flex;
    flex-direction: row;
    gap: ${Dimensions.GridUnit * 4}px;
    flex-wrap: wrap;
    justify-content: center;
`;

export const JobListItem = styled.li`
    width: 100%;
    background-color: ${Color.LightGray};
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: ${Dimensions.GridUnit * 0.5}px;
    gap: ${Dimensions.GridUnit * 2}px;
    flex-basis: 300px;
    padding: ${Dimensions.GridUnit * 2}px;
    box-sizing: border-box;
`;
